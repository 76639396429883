import { createApp } from "vue";
import BootstrapVue from "bootstrap-vue-next";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "crypto-browserify";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.css";
// Создаем приложение Vue
const app = createApp(App);

// Устанавливаем переменные среды в глобальные свойства Vue
app.config.globalProperties.$api = process.env.VUE_APP_API_URL;
app.config.globalProperties.$crm = process.env.VUE_APP_CRM_URL;
app.config.globalProperties.$minio = process.env.VUE_APP_MINIO_URL;

// Используем плагины и монтируем приложение
app.use(BootstrapVue);
app.use(router);
app.use(store);
app.mount("#app");
