import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

const routes = [
  {
    path: "/",
    name: "home",
    meta: { layout: "main", requiresAuth: true },
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      layout: "empty",
      title: "Sign in | America Shipping",
    },
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/cars",
    name: "cars",
    meta: {
      layout: "main",
      title: "Venicles | America Shipping",
      requiresAuth: true,
    },
    component: () => import("../views/cars/CarsView.vue"),
  },
  {
    path: "/statistic",
    name: "statistic",
    meta: {
      layout: "main",
      title: "Statistic | America Shipping",
      requiresAuth: true,
    },
    component: () => import("../views/cars/StatisticView.vue"),
  },
  {
    path: "/archive",
    name: "archive",
    meta: {
      layout: "main",
      title: "Archive | America Shipping",
      requiresAuth: true,
    },
    component: () => import("../views/cars/ArchiveView.vue"),
  },
  {
    path: "/balances",
    name: "balance",
    meta: {
      layout: "main",
      title: "Balance | America Shipping",
      requiresAuth: true,
    },
    component: () => import("../views/office/BalanceView.vue"),
  },
  {
    path: "/calculator",
    name: "calculator",
    meta: {
      layout: "main",
      title: "Calculator | America Shipping",
      requiresAuth: true,
    },
    component: () => import("../views/office/CalculatorView.vue"),
  },
  {
    path: "/carfax",
    name: "carfax",
    meta: {
      layout: "main",
      title: "Carfax | America Shipping",
      requiresAuth: true,
    },
    component: () => import("../views/office/CarfaxView.vue"),
  },
  {
    path: "/autostat",
    name: "autostat",
    meta: {
      layout: "main",
      title: "Autostat | America Shipping",
      requiresAuth: true,
    },
    component: () => import("../views/office/AutostatView.vue"),
  },
  {
    path: "/titles",
    name: "titles",
    meta: {
      layout: "main",
      title: "Titles | America Shipping",
      requiresAuth: true,
    },
    component: () => import("../views/office/TitlesView.vue"),
  },
  {
    path: "/invoices",
    name: "invoices",
    meta: {
      layout: "main",
      title: "Invoices | America Shipping",
      requiresAuth: true,
    },
    component: () => import("../views/office/InvoicesView.vue"),
  },
  {
    path: "/customers",
    name: "customers",
    meta: {
      layout: "main",
      title: "My Customers | America Shipping",
      requiresAuth: true,
    },
    component: () => import("../views/office/CustomersView.vue"),
  },
  {
    path: "/copart",
    name: "copart",
    meta: {
      layout: "main",
      title: "Copart Ground Delivery | America Shipping",
      requiresAuth: true,
    },
    component: () => import("../views/other/CopartView.vue"),
  },
  {
    path: "/iaai",
    name: "iaai",
    meta: {
      layout: "main",
      title: "IAAI Ground Delivery | America Shipping",
      requiresAuth: true,
    },
    component: () => import("../views/other/IaaiView.vue"),
  },
  {
    path: "/frieghts",
    name: "frieghts",
    meta: {
      layout: "main",
      title: "Frieghts | America Shipping",
      requiresAuth: true,
    },
    component: () => import("../views/other/FrieghtsView.vue"),
  },
  {
    path: "/settings",
    name: "settings",
    meta: {
      layout: "main",
      title: "My Settings | America Shipping",
      requiresAuth: true,
    },
    component: () => import("../views/SettingsView.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    meta: {
      layout: "main",
      title: "Profile | America Shipping",
      requiresAuth: true,
    },
    component: () => import("../views/ProfileView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Добавлен хук для проверки состояния аутентификации перед каждым переходом
router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.getters.isAuthenticated;
  //console.log(isAuthenticated);
  if (to.name === "login" && isAuthenticated) {
    // Если пользователь аутентифицирован и пытается зайти на страницу входа,
    // перенаправляем его на главную страницу или другую страницу
    console.log("User is already authenticated, redirecting to home");
    next({ name: "home" }); // Замените "home" на имя вашего маршрута
  } else if (to.meta.requiresAuth && !isAuthenticated) {
    // Если маршрут требует аутентификации и пользователь не аутентифицирован,
    // перенаправляем на страницу входа
    console.log("Not authenticated, redirecting to login");
    next({ name: "login" });
  } else {
    next();
  }
});

export default router;
