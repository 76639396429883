<template>
  <header>
    <nav class="navbar navbar-expand-xl navbar-main navbar-dark bg-dark">
      <div class="container-fluid">
        <a class="navbar-brand" href="/">America Shipping</a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarScroll"
          aria-controls="navbarScroll"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarScroll">
          <ul class="navbar-nav col-lg-9 justify-content-lg-center">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Venicles
              </a>
              <ul class="dropdown-menu">
                <li v-for="car in cars" :key="car.url">
                  <router-link :to="car.url" class="dropdown-item">
                    <i v-if="car.icon" :class="'icon ' + car.icon"></i>
                    {{ car.title }}
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                My Office
              </a>
              <ul class="dropdown-menu">
                <li v-for="office in offices" :key="office.url">
                  <router-link :to="office.url" class="dropdown-item">
                    <i v-if="office.icon" :class="'icon ' + office.icon"></i>
                    {{ office.title }}
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Ground Delivery & Frieght
              </a>
              <ul class="dropdown-menu">
                <li v-for="other in others" :key="other.url">
                  <router-link :to="other.url" class="dropdown-item">
                    <i v-if="other.icon" :class="'icon ' + other.icon"></i>
                    {{ other.title }}
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
          <div class="dropdown content-end lang-menu">
            <a
              href="#"
              class="text-decoration-none dropdown-toggle text-white"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="@/assets/img/eng.png"
                alt="mdo"
                width="32"
                height="30"
              />
              <span class="user-top">ENG</span>
            </a>
            <ul class="dropdown-menu text-small">
              <li><a class="dropdown-item" href="#">ENG</a></li>
              <li><a class="dropdown-item" href="#">UA</a></li>
            </ul>
          </div>
          <div class="dropdown content-end user-menu">
            <a
              href="#"
              class="text-decoration-none dropdown-toggle text-white"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://github.com/mdo.png"
                alt="mdo"
                width="32"
                height="32"
                class="rounded-circle"
              />
              <span class="user-top name-u">NikitaN3</span>
            </a>
            <ul class="dropdown-menu text-small">
              <!--<li>
                <router-link :to="'/settings'" class="dropdown-item">
                  <i class="icon bi bi-sliders mr-4"></i> Settings
                </router-link>
              </li>
              <li>
                <router-link :to="'/profile'" class="dropdown-item">
                  <i class="icon bi bi-person-circle mr-4"></i> Profile
                </router-link>
              </li>
              <li><hr class="dropdown-divider" /></li>-->
              <li>
                <a href="#" class="dropdown-item" @click.prevent="logout">
                  <i class="icon bi bi-box-arrow-right mr-4"></i> Sign out
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
import axios from "axios";

export default {
  data: () => ({
    cars: [
      { title: "List Venicles", url: "/cars", icon: "bi bi-car-front-fill" },
      { title: "Satatistic", url: "/statistic", icon: "bi bi-clipboard-data" },
      { title: "Archive", url: "/archive", icon: "bi bi-file-zip-fill" },
    ],
    offices: [
      // {
      //   title: "Balances",
      //   url: "/balances",
      //   icon: "bi bi-credit-card-2-front",
      // },
      {
        title: "Calculator",
        url: "/calculator",
        icon: "bi bi-calculator-fill",
      },
      { title: "Carfax", url: "/carfax", icon: "bi bi-file-earmark-pdf" },
      { title: "AutoStat", url: "/autostat", icon: "bi bi-bookmarks" },
      {
        title: "Titles",
        url: "/titles",
        icon: "bi bi-file-earmark-excel-fill",
      },
      {
        title: "Invoices",
        url: "/invoices",
        icon: "bi bi-receipt",
      },
      { title: "Customers", url: "/customers", icon: "bi bi-people-fill" },
    ],
    others: [
      { title: "Copart", url: "/copart", icon: false },
      { title: "Iaai", url: "/iaai", icon: false },
      { title: "Frieghts", url: "/frieghts", icon: false },
    ],
  }),
  methods: {
    logout() {
      // Очистить токен доступа из localStorage
      localStorage.removeItem("accessToken");

      // Удалить заголовок Authorization из будущих запросов
      delete axios.defaults.headers.common["Authorization"];

      // Установить флаг аутентификации в хранилище в значение false
      this.$store.commit("setAuthentication", false);

      // Перенаправить пользователя на страницу входа
      this.$router.replace("/login");
    },
  },
};
</script>
<style>
.name-u {
  display: inline-block;
}
.navbar-toggler {
  margin-right: 20px;
}
@media (max-width: 1399px) and (min-width: 1200px) {
  .name-u {
    display: none;
  }
}
</style>
